'use client';
import { useHookFormTracking } from '@finn/ua-tracking';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { LoginForm } from './LoginForm';
import {
  FormFieldsEmail,
  FormFieldsLogin,
  FormFieldsRegistration,
  getValidationSchema,
} from './schemas/validationSchema';
import { useLoginStore } from './store/useLoginStore';

export const Login: React.FC<{
  isLoadingRestore?: boolean;
  isAttemptingDirectCheckout?: boolean;
  vehiclePower?: number | null;
  cartURL: string;
  loginHeaderComponent?: React.ReactNode;
  createLead?: (data) => Promise<void>;
  setExtendedAccountInProgress?: (value: boolean) => void;
  extendedAccountInProgress?: boolean;
}> = ({
  loginHeaderComponent,
  isLoadingRestore,
  isAttemptingDirectCheckout,
  vehiclePower,
  cartURL,
  createLead,
  setExtendedAccountInProgress,
  extendedAccountInProgress,
}) => {
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const formValues = useLoginStore((state) => state.formValues);
  const setFormValues = useLoginStore((state) => state.setFormValues);
  const setServerErrorCode = useLoginStore((state) => state.setServerErrorCode);

  const form = useForm<
    FormFieldsEmail | FormFieldsLogin | FormFieldsRegistration
  >({
    mode: 'onChange',
    resolver: yupResolver(
      getValidationSchema(loginScreen, vehiclePower ?? null)
    ),
    defaultValues: formValues,
  });

  useHookFormTracking(form, loginScreen);

  useEffect(() => {
    // on unmount save the latest form values so user can continue from where they left after re-mounting
    return () => {
      setFormValues(form?.getValues() || {});
      setServerErrorCode(null);
    };
  }, [form, setFormValues, setServerErrorCode]);

  return (
    <FormProvider {...form}>
      <LoginForm
        loginHeaderComponent={loginHeaderComponent}
        isLoadingRestore={isLoadingRestore}
        isAttemptingDirectCheckout={isAttemptingDirectCheckout}
        cartURL={cartURL}
        createLead={createLead}
        setExtendedAccountInProgress={setExtendedAccountInProgress}
        extendedAccountInProgress={extendedAccountInProgress}
      />
    </FormProvider>
  );
};
