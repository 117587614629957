import { Input } from '@finn/design-system/atoms/input';
import { shouldShowFieldError, useIntl } from '@finn/ui-utils';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

type Props = {
  name: string;
  label: string;
  form: UseFormReturn<any>;
  autoFocus?: boolean;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  autoComplete?: string;
  id?: string;
  className?: string;
};

export const HookFormInput: React.FC<Props> = ({
  name,
  label,
  form,
  className,
  type,
  placeholder,
  autoFocus,
  disabled,
  autoComplete,
  id,
}) => {
  const i18n = useIntl();

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <div style={{ position: 'relative' }}>
          <Input
            label={label}
            autoFocus={autoFocus}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autoComplete}
            className={className}
            id={id}
            {...field}
            error={
              shouldShowFieldError(fieldState, form.formState)
                ? i18n.formatMessage(fieldState?.error?.message ?? '')
                : false
            }
          />
        </div>
      )}
    />
  );
};
