import { useDeals } from '@finn/platform-modules';
import {
  NAVIGATION_TRACKING_EVENTS,
  navigationTrackingEvent,
  TrackingEventName,
} from '@finn/ua-tracking';
import { Link } from '@finn/ui-cosmic';
import { cn, HandleClickParams, useSession } from '@finn/ui-utils';
import React, { useCallback, useEffect, useState } from 'react';

import { helpCenterIconClicked } from '../../../tracking';

type Props = { data: Link | undefined };
export const HelpLink = ({ data: helpLink }: Props) => {
  const [session] = useSession();
  const [trackHelpCenterClick, setTrackHelpCenterClick] =
    useState<boolean>(false);

  const isVerified = !!session?.user.email_verified;
  const contactId = session?.user?.hs_contact_id;
  const shouldFetch = !!contactId && isVerified;
  const { deals, dealsStatus } = useDeals({ shouldFetch });

  const customer_id = session?.user?.hs_contact_id;
  helpLink?.href === 'https://support.finn.com/hc/de-de' &&
    (helpLink.href = `${helpLink.href}${
      customer_id ? `?customer_id=${customer_id}` : ''
    }`);

  useEffect(() => {
    if (
      trackHelpCenterClick &&
      (dealsStatus === 'success' || dealsStatus === 'error')
    ) {
      helpCenterIconClicked({
        is_customer: Boolean(deals?.length) || false,
      });

      setTrackHelpCenterClick(false);
    }
  }, [trackHelpCenterClick, dealsStatus, deals]);

  const onHelpClick = useCallback(({ link, type }: HandleClickParams) => {
    navigationTrackingEvent({
      eventName: TrackingEventName.NAVITEM_CLICKED,
      link,
      type,
    });

    setTrackHelpCenterClick(true);
  }, []);

  if (!helpLink?.href) {
    return null;
  }

  return (
    <a
      href={helpLink.href}
      target="_blank"
      rel="noopener noreferrer"
      className={cn(
        'body-16-semibold relative cursor-pointer rounded-sm px-3 py-4',
        'hover:border-pearl hover:bg-snow border border-solid border-white text-black'
      )}
      id="help-center"
      onClick={() =>
        onHelpClick({
          link: helpLink.href,
          type: NAVIGATION_TRACKING_EVENTS.MAIN,
        })
      }
    >
      {helpLink?.label}
    </a>
  );
};
