import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { useGetPreviousCart } from '@finn/ua-vehicle';
import { Image } from '@finn/ui-components';
import {
  CookieKeys,
  getCloudinaryImgUrl,
  getCloudinarySrcSet,
  useIntl,
} from '@finn/ui-utils';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect } from 'react';

import { useGetPreviousDeal } from '~/modules/checkout/hooks/useGetPreviousDeal';

import { AttributesBlock } from './AttributesBlock';
import { ContinueButton } from './ContinueButton';

type Props = {
  open: boolean;
  onClose(): void;
};

export const ReturnToCheckoutModal: React.FC<Props> = ({ open, onClose }) => {
  const i18n = useIntl();
  const { data: dealData } = useGetPreviousDeal();
  const previousCart = useGetPreviousCart();
  const track = useTrackingStore((state) => state.track);
  const data = dealData || previousCart;

  const title = i18n.formatMessage('checkout.returnToCheckout.title');
  const stillInterested = i18n.formatMessage(
    'checkout.returnToCheckout.stillIntersted'
  );

  const cloudinaryURL = getCloudinaryImgUrl(data?.vehicleImage || '', {
    w: 400,
    dpr: 2,
  });
  const srcSet = getCloudinarySrcSet(data?.vehicleImage || '', [400]);

  useEffect(() => {
    track(TrackingEventName.BASKET_VIEWED, {
      location: 'full-basket',
    });
  }, []);

  const dismissModal = useCallback(() => {
    Cookies.set(CookieKeys.REMINDER_DISMISSED, 'true', { expires: 1 });

    onClose();
  }, [onClose]);

  const isMobile = useMediaQuery('sm');

  return (
    <Modal
      variant={isMobile ? 'small' : 'large'}
      open={open}
      onOpenChange={(isOpen) => !isOpen && dismissModal()}
    >
      <ModalContent className="z-modalOverlay">
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        <div className="bg-snow p-6">
          <div className="flex flex-col sm:flex-row">
            <div className="flex shrink-0 grow-0 basis-full flex-col gap-4 sm:basis-1/2 sm:gap-6">
              <span className="body-14-semibold">{stillInterested}</span>
              <h5 className="global-t5-semibold">
                {data?.brand} {data?.model}
              </h5>

              <div className="hidden sm:block">
                <AttributesBlock />
                <ContinueButton onClose={dismissModal} />
              </div>
            </div>
            <div className="shrink-0 grow-0 basis-full sm:basis-1/2">
              <Image
                className="max-w-full"
                src={cloudinaryURL}
                srcSet={srcSet}
                alt={`${data?.brand} ${data?.model}`}
              />
            </div>
          </div>
          <div className="block sm:hidden">
            <AttributesBlock />
            <ContinueButton onClose={dismissModal} />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
