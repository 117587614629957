import { useMediaQuery } from '@finn/design-system/helpers/media';
import { type HandleMobileHeaderParams } from '@finn/ua-header';
import { CTACosmicMetadata, HeaderData } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import { useRouter } from 'next/compat/router';
import React, { useEffect, useState } from 'react';

import { useMenuData } from '../utils';
import HeaderDrawer from './HeaderDrawer';
import Navbar from './Navbar';

const GeneralMobileHeader = ({
  data,
  setHideRibbon,
  ribbonDismissed,
  className,
  ctaData,
}: {
  data: HeaderData;
  setHideRibbon: React.Dispatch<React.SetStateAction<boolean>>;
  ribbonDismissed: boolean;
  className?: string;
  ctaData?: CTACosmicMetadata;
}) => {
  const router = useRouter();
  const navbarData = useMenuData(data);
  const [isMainNavOpen, setIsMainNavOpen] = useState<boolean>(false);
  const [isUserNavOpen, setIsUserNavOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isMainNavOpen || isUserNavOpen) {
      setHideRibbon(true);
    } else if (!ribbonDismissed) {
      setHideRibbon(false);
    }
  }, [isMainNavOpen, isUserNavOpen, setHideRibbon]);

  const handleMobileHeader = ({
    isOpen,
    isOpenUser,
  }: HandleMobileHeaderParams) => {
    setIsMainNavOpen(isOpen);
    setIsUserNavOpen(isOpenUser);
  };

  // useEffect(() => {
  //   router.events.on('routeChangeComplete', () => {
  //     setIsMainNavOpen(false);
  //     setIsUserNavOpen(false);
  //   });

  //   return () => {
  //     router.events.off('routeChangeComplete', () => {
  //       setIsMainNavOpen(false);
  //       setIsUserNavOpen(false);
  //     });
  //   };
  // }, [router.events]);
  // const pathname = usePathname();

  useEffect(() => {
    setIsMainNavOpen(false);
    setIsUserNavOpen(false);
  }, [router?.pathname]);

  const isMobile = useMediaQuery('md');

  return (
    <>
      <Navbar
        handleMobileHeader={handleMobileHeader}
        isOpen={isMainNavOpen}
        isOpenUser={isUserNavOpen}
        headerData={data}
        ctaData={ctaData}
        className={cn(
          isMainNavOpen || isUserNavOpen ? '!z-modalOverlay' : '',
          className
        )}
      />

      {isMobile && (
        <HeaderDrawer
          isOpen={isMainNavOpen}
          isOpenUser={isUserNavOpen}
          handleMobileHeader={handleMobileHeader}
          data={navbarData}
          headerData={data}
        />
      )}
    </>
  );
};

export default GeneralMobileHeader;
