import { CTACosmicMetadata, HeaderData } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';

import Navbar from './Navbar';

const GeneralDesktopHeader = ({
  data,
  ribbonDismissed,
  className,
  ctaData,
}: {
  data: HeaderData;
  ribbonDismissed: boolean;
  className?: string;
  ctaData?: CTACosmicMetadata;
}) => (
  <div
    data-nativeappid="header"
    className={cn('container bg-white', className)}
  >
    <Navbar data={data} ribbonDismissed={ribbonDismissed} ctaData={ctaData} />
  </div>
);

export default GeneralDesktopHeader;
