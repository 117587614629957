import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import { useCheckoutParams, useUniversalRouter } from './useCheckoutHelpers';

export const useHubspotDealInfo = () => {
  const router = useUniversalRouter();
  const searchParams = useSearchParams();

  const [contactId, dealId, hash] = useCheckoutParams();
  const query = router?.query || Object.fromEntries(searchParams || new Map());

  const queryContactId = query?.contactId as string;
  const queryDealId = query?.dealId as string;
  const queryDealHash = query?.dealHash as string;

  return useMemo(
    () =>
      queryContactId
        ? {
            contactId: parseInt(queryContactId),
            dealId: parseInt(queryDealId),
            hash: queryDealHash,
          }
        : {
            contactId: parseInt(contactId),
            dealId: parseInt(dealId),
            hash,
          },
    [queryContactId, queryDealId, queryDealHash, contactId, dealId, hash]
  );
};
