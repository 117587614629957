import { HeaderData, HeaderLinkData, Link, NavbarData } from '@finn/ui-cosmic';
import { useCurrentLocale } from '@finn/ui-utils';
import get from 'lodash/get';
import { useMemo } from 'react';

export const useMenuData = (cosmicPageData: HeaderData): NavbarData => {
  const { isDE } = useCurrentLocale();

  return useMemo(() => {
    const newLinks: HeaderLinkData[] = [...cosmicPageData.metadata.links];

    const links = newLinks.map((link) => link.metadata as Link);

    const { ribbon } = cosmicPageData.metadata;
    const userAccountLinks =
      cosmicPageData.metadata.user_accounts?.navbar_user_account_links || {};

    userAccountLinks.dropdownOptions = get(
      cosmicPageData,
      'metadata.user_accounts.dropdown_options'
    );
    userAccountLinks.helpLink = get(
      cosmicPageData,
      'metadata.help_link.metadata'
    );

    return {
      ribbon,
      links,
      userAccountLinks,
    };
  }, [cosmicPageData, isDE]);
};
