import {
  NAVIGATION_TRACKING_EVENTS,
  navigationTrackingEvent,
  TrackingEventName,
} from '@finn/ua-tracking';
import { NextLinkSingleApp } from '@finn/ui-components';
import { NavbarData } from '@finn/ui-cosmic';
import { App, cn, type HandleClickParams } from '@finn/ui-utils';
import throttle from 'lodash/throttle';
import React, { useCallback, useEffect, useState } from 'react';

import Menu from './Menu';

type MainNavbarNavigationLinksProps = {
  hasRibbon: boolean;
  links: NavbarData['links'];
  isMobile: boolean;
  children?: React.ReactNode;
};

const MainNavbarNavigationLinks = ({
  links,
  hasRibbon,
  isMobile,
  children,
}: MainNavbarNavigationLinksProps) => {
  const [showMenu, setShowMenu] = useState(false);

  // The purpose of handling hover styles with JS is to hide the menu on click.
  const handleMouseEnter = () => {
    setShowMenu(true);
  };
  const handleMouseLeave = () => {
    setShowMenu(false);
  };

  const handleClick = useCallback(({ link, type, e }: HandleClickParams) => {
    navigationTrackingEvent({
      eventName: TrackingEventName.NAVITEM_CLICKED,
      link,
      type,
    });
    setShowMenu(false);
    e?.stopPropagation();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowMenu(false);
    };
    document.addEventListener('scroll', throttle(handleScroll, 100), {
      passive: true,
    });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <menu className="flex items-center">
      {links.map((link, idx) => (
        <li
          key={link.label + link.href + idx}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={cn(
            // generic styles
            'relative mr-3 list-none rounded-sm border border-solid border-white text-black',
            // some weard hacks that I just kept
            '[&_#menu]:hover:!block [&_#menu]:[&.hideMenu]:!hidden',
            // hover effects
            'hover:border-pearl hover:bg-snow',
            // another weard part
            { 'hideMenu border-white bg-white': !showMenu }
          )}
        >
          <NextLinkSingleApp
            app={App.UA}
            href={link.href}
            passHref
            className="body-16-semibold block cursor-pointer p-3 py-4"
            onClick={(event) =>
              handleClick({
                link: link.href,
                type: NAVIGATION_TRACKING_EVENTS.MAIN,
                e: event,
              })
            }
          >
            {link.label}
          </NextLinkSingleApp>

          {!isMobile && link.menu && (
            <Menu
              data={link.menu}
              hasRibbon={hasRibbon}
              handleClick={handleClick}
            />
          )}
        </li>
      ))}
      {children}
    </menu>
  );
};

export default MainNavbarNavigationLinks;
