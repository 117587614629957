import {
  ageBoundaries,
  OLD_DRIVER_ADDON_ID,
  OLD_DRIVER_SURCHARGE,
  YOUNG_DRIVER_ADDON_ID,
  YOUNG_DRIVER_SURCHARGE,
} from '@finn/ua-constants';
import { IntlShape } from '@finn/ui-utils';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const getYoungOldDriverData = ({
  birthday,
  intl,
}: {
  birthday: string | Date | Dayjs;
  intl: IntlShape;
}) => {
  if (!birthday) {
    return null;
  }

  const birthdayDate =
    typeof birthday === 'string'
      ? dayjs(birthday, 'DD.MM.YYYY')
      : dayjs(birthday);

  if (!birthdayDate.isValid()) {
    return null;
  }

  const today = dayjs();
  const age = today.diff(birthdayDate, 'year');

  // Early return if age is outside allowed range
  if (age < ageBoundaries.MIN_EXCEPTIONAL) {
    return null;
  }

  const isYoungDriver = age < ageBoundaries.YOUNG_DRIVER_THRESHOLD;
  const isOldDriver = age > ageBoundaries.OLD_DRIVER_THRESHOLD;

  if (isYoungDriver) {
    return {
      label: intl.formatMessage(
        'checkout.addons.youngOld.youngDriverSurchargeLabel'
      ),
      message: intl.formatMessage(
        'checkout.addons.youngOld.youngDriverMessage'
      ),
      surcharge: YOUNG_DRIVER_SURCHARGE,
      addonId: YOUNG_DRIVER_ADDON_ID,
    };
  }

  if (isOldDriver) {
    return {
      label: intl.formatMessage(
        'checkout.addons.youngOld.oldDriverSurchargeLabel'
      ),
      message: intl.formatMessage('checkout.addons.youngOld.oldDriverMessage'),
      surcharge: OLD_DRIVER_SURCHARGE,
      addonId: OLD_DRIVER_ADDON_ID,
    };
  }

  return null;
};
