/*
  The goal of this hook is to provide simple abstraction to fetch one of the predefined
  data types from the remote server. Basically hook uses our custom made dataFetcher
  and injects into it some data rom user session + makes sure to avoid unnecessary
  re-renders and re-fetches.

  It's also map the data to the same format and returns the same data structure for
  loading state machines.

  For example useRemoteData('subscriptions') will return list of subscriptions and loading state in such way
  { items: Subscription[], status: 'loading' | 'error' | 'success' }
*/

import { useSession } from '@finn/ui-utils';
import { useMemo } from 'react';
import useSWR from 'swr';

import { dataFetcher, GetEndpoint } from './data-fetcher';

const config = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export type RequestStatus = 'loading' | 'error' | 'success';

export const getDataStatus = (statuses: RequestStatus[]) => {
  if (statuses.includes('loading')) {
    return 'loading' as const;
  }

  if (statuses.includes('error')) {
    return 'error' as const;
  }

  return 'success' as const;
};

export const getRequestStatus = (
  requestStatus: number | undefined,
  isLoading: boolean,
  error: unknown,
  key?: string
): RequestStatus => {
  if (isLoading || (!requestStatus && key)) {
    return 'loading' as const;
  }

  if (error || (requestStatus && requestStatus >= 400)) {
    return 'error' as const;
  }

  return 'success' as const;
};

export const useRemoteData = <T = unknown,>(
  key: string | null,
  getEndpoint: GetEndpoint,
  fallback?: T
) => {
  const [session, isSessionLoading] = useSession();
  // hack to avoid error during build if bff is not under [lang]
  // const getLocalizedEndpoint = useMemo(
  //   () => () => {
  //     const path = getEndpoint();

  //     const insertLocale = (oldPath: string) => {
  //       const routes = oldPath.split('/');
  //       const indexOfLocale = routes.indexOf('portal') + 1;

  //       return routes.splice(indexOfLocale, 0, 'de-DE').join('/');
  //     };

  //     return Array.isArray(path) ? path.map(insertLocale) : insertLocale(path);
  //   },
  //   [getEndpoint]
  // );
  const fetcher = useMemo(() => dataFetcher(getEndpoint), [getEndpoint]);
  const mayHaveAnAccount = isSessionLoading || session;
  const { data, error, isLoading, mutate } = useSWR<{
    data: { data: T };
    status: number;
  }>(key, fetcher, config);

  return useMemo(
    () => ({
      mutate,
      data: data?.data?.data || fallback,
      status: mayHaveAnAccount
        ? getRequestStatus(data?.status, isLoading, error, key ?? undefined)
        : ('success' as const),
      httpStatus: data?.status,
    }),
    [mutate, data, isLoading, mayHaveAnAccount, error, fallback, key]
  );
};
