import { Modal, ModalContent } from '@finn/design-system/atoms/modal';

import { Login } from './Login';

type LoginModalProps = {
  open: boolean;
  className?: string;
  onClose(): void;
  cartURL: string;
  onCloseCallback?: () => void;
};

export const LoginModal = ({
  open,
  onClose,
  className,
  cartURL,
  onCloseCallback,
}: LoginModalProps) => (
  <Modal
    variant="small"
    open={open}
    onOpenChange={(isOpen) => {
      !isOpen && onClose();
      !isOpen && onCloseCallback?.();
    }}
  >
    <ModalContent>
      <div className={className}>
        <Login cartURL={cartURL} />
      </div>
    </ModalContent>
  </Modal>
);
