import { supportNavbarClicked } from '@finn/ua-tracking';
import { TrustPilotRating, TrustpilotStyle } from '@finn/ua-vehicle';
import { useIntl } from '@finn/ui-utils';
import { get } from 'lodash';
import { useContext } from 'react';

import CheckoutContext from '~/contexts/Checkout';

export const CheckoutFooterTopSection = () => {
  const i18n = useIntl();
  const { pageData } = useContext(CheckoutContext);
  const needHelpText = i18n.formatMessage('checkout.needHelp');
  const operatingTime = i18n.formatMessage('checkout.operatingTime');
  const phoneNumber = get(
    pageData,
    'metadata.header.metadata.need_help.phone_number',
    []
  );

  return (
    <div className="border-pearl border border-solid bg-white">
      <div className="container">
        <div className="flex flex-col justify-between md:flex-row">
          <div className="flex w-full flex-col gap-4 px-0 pb-0 pt-6 md:flex-row md:py-6">
            <p className="body-16-semibold">{needHelpText}</p>
            <a
              href={get(phoneNumber, 'metadata.href')}
              onMouseDown={() => {
                supportNavbarClicked();
              }}
              className="cursor-pointer underline"
            >
              <p className="body-14-semibold">
                {get(phoneNumber, 'metadata.label')}
              </p>
            </a>
            <p className="body-14-light">{operatingTime}</p>
          </div>
          <div className="flex w-full items-center justify-start self-center py-4 text-left md:justify-end">
            <TrustPilotRating
              style={TrustpilotStyle.Checkout}
              mobileClassName="-left-[40px]"
              mobileWidth="250px"
              width="250px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
