import { SelfServiceRoutes } from '@finn/ua-constants';
import { HeaderContext } from '@finn/ua-header';
import { NavbarData } from '@finn/ui-cosmic';
import { cn, useSession } from '@finn/ui-utils';
import React, { useContext, useEffect, useState } from 'react';

import { ProfileButton } from '../../../Components/ProfileButton';
import DropdownLinksList from './DropdownLinksList';
import { HelpLink } from './HelpLink';

type Props = {
  data: NavbarData['userAccountLinks'];
  showHelpLink?: boolean;
};

const NavbarUserAccount: React.FunctionComponent<Props> = ({
  data,
  showHelpLink = true,
}) => {
  const [session] = useSession();
  const [isSessionLoading, setIsSessionLoading] = useState<boolean>(true);
  const { CartButton } = useContext(HeaderContext);

  useEffect(() => {
    // Since session is null when user is not logged in and undefined if it is still loading
    if (session || session === null) {
      setIsSessionLoading(false);
    }
  }, [session]);

  const dropDownOption = Object.keys(data?.dropdownOptions || {});
  const useAccountDropDownList =
    dropDownOption.length > 1 || dropDownOption[0] !== 'sign_out';

  if (isSessionLoading) {
    return <div className="flex items-center justify-between" />;
  }

  return (
    <div className="flex items-center justify-between">
      {showHelpLink && <HelpLink data={data.helpLink} />}
      {CartButton && <CartButton />}
      <div className="group ml-1 box-content">
        <ProfileButton
          id="dropdown-button"
          onClick={() => {
            // TODO migrate this component to be link
            window.location.href = `/portal/de-DE${SelfServiceRoutes.MY_CARS}`;
          }}
        />
        {useAccountDropDownList && session && (
          <div
            id="dropdown"
            className={cn(
              'z-overlay absolute -right-4 box-content hidden pt-2'
            )}
          >
            <div className="box-content w-48 rounded-sm bg-white p-4 drop-shadow-2xl">
              <DropdownLinksList data={data.dropdownOptions} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarUserAccount;
