import { HookFormInput } from '@finn/ui-components';
import { useIntl } from '@finn/ui-utils';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { HookFormDatePicker } from '../../components/DatePicker/HookFormDatePicker';
import { HookFormPhoneInput } from '../../components/HookFormPhoneInput';
import { getYoungOldDriverData } from '../../helpers/youngOldDriver';

const placeholderPrefix = 'checkout.pageData.input_field_placeholders';

export const ExtendedAccountFields: React.FC = () => {
  const form = useFormContext();
  const i18n = useIntl();
  const firstNameLabel = i18n.formatMessage(`${placeholderPrefix}.firstname`);
  const lastNameLabel = i18n.formatMessage(`${placeholderPrefix}.lastname`);

  const birthday = useWatch({ name: 'birthday' });
  const youngOldDriverData = getYoungOldDriverData({
    birthday,
    intl: i18n,
  });

  return (
    <>
      <HookFormInput
        name="firstname"
        label={firstNameLabel}
        form={form}
        autoFocus={true}
      />
      <HookFormInput name="lastname" label={lastNameLabel} form={form} />
      <HookFormDatePicker
        name="birthday"
        label={i18n.formatMessage(`${placeholderPrefix}.birthday`)}
        form={form}
      />
      {youngOldDriverData && (
        <p className="body-14-light">{youngOldDriverData.message}</p>
      )}
      <HookFormPhoneInput
        name="phone"
        label={i18n.formatMessage(`${placeholderPrefix}.phone`)}
        placeholder={i18n.formatMessage(
          `${placeholderPrefix}.phone_number_format`
        )}
        form={form}
      />
    </>
  );
};
