import { useMediaQuery } from '@finn/design-system/helpers/media';
import { Logo } from '@finn/design-system/icons/logo';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { CTA, NextLinkSingleApp } from '@finn/ui-components';
import { CTACosmicMetadata, HeaderData } from '@finn/ui-cosmic';
import { App, useSession } from '@finn/ui-utils';
import { useCallback } from 'react';

import { useMenuData } from '../../utils';
import MainNavbarNavigationLinks from './MainNavbarNavigationLinks';
import NavbarUserAccountLink from './NavbarUserAccountLinks';
import { HelpLink } from './NavbarUserAccountLinks/HelpLink';

const Navbar = ({
  data,
  ribbonDismissed,
  ctaData,
}: {
  data: HeaderData;
  ribbonDismissed: boolean;
  ctaData?: CTACosmicMetadata;
}) => {
  const shouldDisableLogoLink = data?.metadata?.disable_logo_link;
  const customLogo = data?.metadata?.custom_logo?.url;
  const customLogoLink = data?.metadata?.custom_logo_link;
  const logoTitle = data?.metadata?.logo_title;
  const logoHref = customLogoLink ? customLogoLink : '/';
  const navbarData = useMenuData(data);
  const [, loadingSession] = useSession();

  const trackLogoClick = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.FINN_LOGO_CLICKED, {
      location: 'common-header',
    });
  }, []);

  const isMobile = useMediaQuery('md');

  const LogoElement = () =>
    customLogo ? (
      <img className="h-14 w-auto" src={customLogo} alt="parner-logo" />
    ) : (
      <Logo />
    );

  // CTA is shown next to user account links. To avoid too much flickering, we only show it when the session is loaded.
  const shouldShowCTA = ctaData && !loadingSession;

  return (
    <div className="relative flex h-20 items-center">
      <div className="mr-8">
        {shouldDisableLogoLink ? (
          <LogoElement />
        ) : (
          <NextLinkSingleApp
            app={App.UA}
            href={logoHref}
            className="flex"
            onClick={trackLogoClick}
            title={logoTitle}
          >
            <LogoElement />
          </NextLinkSingleApp>
        )}
      </div>
      <div className="flex w-full items-center justify-between">
        <MainNavbarNavigationLinks
          links={navbarData.links}
          hasRibbon={!!navbarData.ribbon?.enabled && !ribbonDismissed}
          isMobile={isMobile}
        >
          {ctaData && <HelpLink data={navbarData.userAccountLinks?.helpLink} />}
        </MainNavbarNavigationLinks>
        <div className="flex items-center">
          {shouldShowCTA && <CTA data={ctaData} size="md" />}
          <NavbarUserAccountLink
            showHelpLink={!ctaData}
            data={navbarData.userAccountLinks}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
