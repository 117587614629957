// this helpers simplifies the way to read checkout params in universal way,
// basically it works for both app and pages router

import { getSlugsWithoutStep } from '@finn/ua-auth';
import { useDiscoveryConfig } from '@finn/ua-vehicle';
import { useRouter } from 'next/compat/router';
import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

// if router null, we are in app router, isReady always true
// otherwise we do as before
export const useIsRouterReady = () => {
  const router = useRouter();

  return router ? router.isReady : true;
};

export const useUniversalRouter = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { router: appRouter } = useDiscoveryConfig();
  const searchParams = useSearchParams();
  const queryStr = searchParams.toString() ? `?${searchParams.toString()}` : '';
  const fallbackRouter = useMemo(
    () => ({
      locale: 'de-DE',
      asPath: `${pathname}${queryStr}`,
      pathname,
      isReady: true,
      query: Object.fromEntries(searchParams || new Map()),
      ...appRouter,
    }),
    [appRouter, pathname, queryStr, searchParams]
  );

  return router || fallbackRouter;
};

export const useCheckoutParams = () => {
  const router = useRouter();
  const params = useParams();
  const searchParams = useSearchParams();

  return getSlugsWithoutStep(
    params?.slug || router?.query?.slug || searchParams.get('slug')
  );
};
