import { type Breadcrumb } from '@finn/ua-auth';
import {
  type FiltersResponse,
  type GenericVehicleDetails,
} from '@finn/ua-vehicle';
import { type AccordionCosmicData, type ModuleType } from '@finn/ui-cosmic';
import { CosmicModuleType } from '@finn/ui-cosmic/ssr';
import { FakeDefaultLocale, replaceStrKeys, slugify } from '@finn/ui-utils/ssr';
import get from 'lodash/get';
import trimEnd from 'lodash/trimEnd';

import { getVehicleMinPrice } from '~/utils/pdp';

export const createMarkup = (jsonLD: string) => ({ __html: jsonLD });

type TrustpilotData = {
  ratingValue: string;
  ratingCount: string;
};

export const buildProductJsonLdAsStr = (
  vehicle: GenericVehicleDetails,
  { ratingValue, ratingCount }: TrustpilotData
) => {
  const name = `${get(vehicle, 'brand.id', '')} ${get(vehicle, 'model', '')}`;
  const availability = `https://schema.org/${
    vehicle.availability ? 'InStock' : 'OutOfStock'
  }`;
  const image = get(vehicle, 'picture.url', '');
  const description = get(vehicle, 'description', '');
  const price = getVehicleMinPrice(vehicle);
  const origin = process.env.URL || process.env.DEPLOY_URL;

  const jsonLD = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue,
      ratingCount,
    },
    name,
    image,
    description,
    brand: {
      '@type': 'Brand',
      name: get(vehicle, 'brand.id', ''),
    },
    color: get(vehicle, 'color.specific', ''),
    category: get(vehicle, 'cartype', ''),
    offers: {
      '@type': 'Offer',
      url: `${origin}/pdp/${vehicle.id}`,
      priceCurrency: 'EUR',
      price,
      availability,
    },
  };

  return JSON.stringify(jsonLD, null, 2);
};

type BrandProductJsonLdParams = {
  brand: string;
  locale: string;
  filtersResponse: FiltersResponse;
  trustpilotData: TrustpilotData;
  content: {
    title: string;
    description: string;
  };
};
export const buildBrandProductJsonLdAsStr = ({
  brand,
  filtersResponse,
  trustpilotData: { ratingValue, ratingCount },
  locale,
  content: { title = '', description = '' },
}: BrandProductJsonLdParams) => {
  const availability = filtersResponse.total_results;
  const origin =
    process.env.URL || process.env.DEPLOY_URL || 'https://www.finn.com';
  const lowPrice = filtersResponse.min_price;
  const highPrice = filtersResponse.max_price;

  const jsonLD = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue,
      ratingCount,
    },
    name: replaceStrKeys(title, { brand }),
    image: '',
    description: replaceStrKeys(description, { brand, lowPrice }),
    brand: {
      '@type': 'Brand',
      name: brand,
    },
    offers: {
      '@type': 'AggregateOffer',
      url: `${origin}/${locale}/subscribe/${slugify(brand)}`,
      priceCurrency: 'EUR',
      lowPrice,
      highPrice,
      offerCount: availability,
    },
  };

  return JSON.stringify(jsonLD, null, 2);
};

export const buildBreadcrumbsJsonLdAsStr = (
  breadcrumbs: Breadcrumb[],
  locale: string
) => {
  const origin =
    process.env.URL || process.env.DEPLOY_URL || 'https://www.finn.com';
  const localePrefix = locale !== FakeDefaultLocale ? `/${locale}` : '';

  const jsonLD = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb, idx) => ({
      '@type': 'ListItem',
      position: idx + 1,
      name: breadcrumb.title,
      item: breadcrumb.url
        ? trimEnd(`${origin}${localePrefix}${breadcrumb.url}`, '/')
        : undefined,
    })),
  };

  return JSON.stringify(jsonLD, null, 2);
};

export const buildFAQJsonLdAsStrForPage = (modules: ModuleType[]) => {
  const faqItems = [];
  const accordionModules = (modules || []).filter(
    (module) =>
      module?.type === CosmicModuleType.WEB_MODULE_ACCORDION ||
      module?.type_slug === CosmicModuleType.WEB_MODULE_ACCORDION ||
      module?.type === CosmicModuleType.WEB_MODULE_ACCORDION_OLD
  );
  if (!accordionModules.length) return null;
  accordionModules.forEach((module: AccordionCosmicData) => {
    if (
      module.type === CosmicModuleType.WEB_MODULE_ACCORDION ||
      module?.type_slug === CosmicModuleType.WEB_MODULE_ACCORDION
    ) {
      const itemInCurrentModule = module?.metadata?.items;
      if (itemInCurrentModule?.length) {
        faqItems.push(...itemInCurrentModule);
      }
    } else {
      const item = module?.metadata;
      faqItems.push(item);
    }
  });

  const jsonLD = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqItems.map((item) => ({
      '@type': 'Question',
      name: item?.metadata?.title || item?.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item?.metadata?.description || item?.answer,
      },
    })),
  };

  return JSON.stringify(jsonLD, null, 2);
};
