import { CTACosmicMetadata, HeaderData } from '@finn/ui-cosmic';
import { CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { useRouter } from 'next/compat/router';
import { memo, useEffect, useState } from 'react';

import GeneralDesktopHeader from './GeneralDesktopHeader';
import GeneralMobileHeader from './GeneralMobileHeader';
import { HeaderProvider, HeaderProviderProps } from './HeaderContext';
import { Ribbon } from './Ribbon';
import { useRibbonStore } from './useRibbonStore';

export type HandleMobileHeaderParams = {
  isOpen: boolean;
  isOpenUser: boolean;
};

export type HeaderProps = HeaderProviderProps & {
  data: HeaderData;
  sticky?: boolean;
  stickyCTAData?: CTACosmicMetadata;
};

const Header = ({
  data,
  CartButton,
  sticky = false,
  stickyCTAData,
}: HeaderProps) => {
  const { isRibbonDismissed, setIsRibbonDismissed } = useRibbonStore();
  const [hideRibbon, setHideRibbon] = useState(false);
  const router = useRouter();
  const pathname = router?.asPath;

  useEffect(() => {
    setIsRibbonDismissed(!!Cookies.get(CookieKeys.RIBBON_DISMISSED));
  }, []);

  // pre-dismiss the ribbon for people visiting partnerships and club pages for 30 days
  useEffect(() => {
    if (pathname?.includes('/partnerships/') || pathname?.includes('/club')) {
      setIsRibbonDismissed(true);
      Cookies.set(CookieKeys.RIBBON_DISMISSED, 'true', { expires: 30 });
    }
  }, [pathname]);

  const onRibbonDismiss = () => {
    setIsRibbonDismissed(true);
  };

  return (
    <HeaderProvider CartButton={CartButton}>
      <header
        data-testid="header"
        className={sticky ? 'z-sticky sticky top-0 bg-white' : ''}
      >
        {!hideRibbon && <Ribbon data={data} onDismiss={onRibbonDismiss} />}
        <GeneralDesktopHeader
          data={data}
          ctaData={stickyCTAData}
          ribbonDismissed={isRibbonDismissed}
          className="hidden md:block"
        />
        <GeneralMobileHeader
          data={data}
          ctaData={stickyCTAData}
          setHideRibbon={setHideRibbon}
          ribbonDismissed={isRibbonDismissed}
          className="flex md:hidden"
        />
      </header>
    </HeaderProvider>
  );
};

export default memo(Header);
