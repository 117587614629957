import { RECAPTCHA_SITE_KEY } from '@finn/ua-constants';
import { useIntl } from '@finn/ui-utils';
import Script from 'next/script';

declare global {
  //eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    grecaptcha: unknown;
  }
}

export const recaptchaCall = async (): Promise<string> => {
  const recaptcha = window?.grecaptcha as {
    ready: Function;
    execute: Function;
  };

  const tokenPromise: Promise<string> = new Promise((resolve) => {
    recaptcha.ready(() => {
      recaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' }).then(resolve);
    });
  });

  return await tokenPromise;
};

export const Recaptcha = () => {
  const i18n = useIntl();

  return (
    <div className="body-14-light py-2 [&_a]:font-semibold [&_a]:underline">
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`}
      />
      {i18n.formatMessage('general.recaptchaPrivacyText')}
    </div>
  );
};
