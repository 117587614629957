export interface Breadcrumb {
  title: string;
  url?: string;
}

interface BreadcrumbsProps<T extends Breadcrumb = Breadcrumb> {
  className?: string;
  breadcrumbs: T[];
  onBreadcrumbClick?: (breadcrumb: T, index: number) => void;
}

export default function Breadcrumbs<T extends Breadcrumb = Breadcrumb>({
  className,
  breadcrumbs,
  onBreadcrumbClick,
}: BreadcrumbsProps<T>) {
  if (!breadcrumbs || breadcrumbs.length === 0) {
    return null;
  }

  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

  const handleOnBreadcrumbClick = (breadcrumb: T, index: number) => {
    onBreadcrumbClick?.(breadcrumb, index);
  };

  return (
    <nav aria-label="Breadcrumb" className={className}>
      <ul
        className="body-14-regular my-4 flex list-none items-center p-0"
        data-appid="breadcrumbs"
      >
        {breadcrumbs.slice(0, -1).map((breadcrumb, index) => (
          <li key={breadcrumb.url} className="flex whitespace-nowrap">
            {breadcrumb.url ? (
              <a
                href={breadcrumb.url}
                onClick={() => handleOnBreadcrumbClick(breadcrumb, index)}
              >
                {breadcrumb.title}
              </a>
            ) : (
              <span>{breadcrumb.title}</span>
            )}
            {/* Delimiter */}
            <div className="mx-2">-</div>
          </li>
        ))}
        <li key="current-breadcrumb" className="flex truncate">
          {lastBreadcrumb.url ? (
            <a
              href={lastBreadcrumb.url}
              className="truncate"
              onClick={() =>
                handleOnBreadcrumbClick(lastBreadcrumb, breadcrumbs.length - 1)
              }
            >
              {lastBreadcrumb.title}
            </a>
          ) : (
            <span className="text-steel truncate">{lastBreadcrumb.title}</span>
          )}
        </li>
      </ul>
    </nav>
  );
}
