import { Button, ButtonProps } from '@finn/design-system/atoms/button';
import { TrackingEventName } from '@finn/ua-tracking';
import {
  CTACosmicMetadata,
  TextblockAddProps,
  TrackingDataNewFormat,
} from '@finn/ui-cosmic';
import {
  App,
  cn,
  getAnchorTarget,
  isNavigatingToSamePage,
  localizeUrl,
  trackEvent,
  useCurrentLocale,
} from '@finn/ui-utils';
import { useRouter } from 'next/compat/router';
import React, { useEffect, useState } from 'react';

import { CTALink } from './CTALink';
import { NextLinkSingleApp } from './NextLinkSingleApp';

export const useWebViewNavigationEnded = (
  loading: boolean,
  setLoading: (value: boolean) => void
) => {
  useEffect(() => {
    const appSDK = window?.nativeAppSDK;

    const stopCTALoading = () => {
      if (loading) {
        setLoading(false);
      }
    };

    if (loading) {
      const unsubscribe = appSDK?.on('navigation_ended', stopCTALoading);

      return () => {
        unsubscribe?.();
      };
    }

    return () => {
      // empty
    };
  }, [loading, setLoading]);
};

type Props = {
  data: CTACosmicMetadata;
  size?: ButtonProps['size'];
  textColor?: 'white' | 'black';
  parentBG?: 'dark' | 'light';
  onCTAClick?: Function;
  addProps?: TextblockAddProps;
  targetApp?: App; // defaults to App.UA for links pointing to CP use App.CP
  fullWidth?: boolean;
  isExpHPHeroFilters?: boolean;
  classNames?: string;
};

enum CTAType {
  button = 'button', // CTA shown as button
  link = 'link', // CTA shown as link
}

const handleCTAClick = (trackingDataNewFormat?: TrackingDataNewFormat) => {
  trackEvent(TrackingEventName.CTA_CLICKED, trackingDataNewFormat || {});
};

export const CTA: React.FunctionComponent<Props> = ({
  size = 'lg',
  data,
  textColor,
  parentBG,
  onCTAClick = () => {},
  addProps,
  targetApp,
  fullWidth = false,
  classNames,
}) => {
  const { locale } = useCurrentLocale();
  const router = useRouter();
  const tracking_location = data?.tracking_location;
  const trackingDataNewFormat = {
    name: data.text,
    link: data.href,
    location: tracking_location ?? data.text,
  };
  const [loading, setLoading] = useState(false);

  // used to disable loading in webview after navigation is done
  useWebViewNavigationEnded(loading, setLoading);

  useEffect(() => {
    const onRouteComplete = setLoading.bind(null, false);
    router?.events?.on('routeChangeComplete', onRouteComplete);

    return () => {
      router?.events?.off('routeChangeComplete', onRouteComplete);
    };
  }, [router?.events]);

  const handleLoadingState = () => {
    if (
      data.href &&
      !isNavigatingToSamePage(data.href) &&
      getAnchorTarget(data.href) !== '_blank' &&
      !data?.new_tab &&
      !data.href.startsWith('#')
    ) {
      setTimeout(() => {
        setLoading(true);
        // set on next tick to load direct link (without NextLink)
      }, 0);
    }
  };

  const hasBorder = data.with_border || addProps?.ctaBorder;
  const href = data.href || addProps?.ctaHref;

  // some slitly weard legacy condition
  const buttonVariant =
    (hasBorder && (parentBG === 'light' ? 'outline' : 'outlineWhite')) ||
    'primary';

  return (
    <>
      {data.cta_type.key === CTAType.button ? (
        <div
          className={cn(
            // TODO after Hero Filters v3 cleanup if accepted: adjust width of row to align with title
            { 'w-full': fullWidth },
            classNames
          )}
          onClick={() => {
            handleCTAClick(trackingDataNewFormat);
            handleLoadingState();
            onCTAClick();
          }}
        >
          {href ? (
            <NextLinkSingleApp
              app={targetApp || App.UA}
              withoutAnchorTag
              href={href}
              as={href}
            >
              <Button
                size={size}
                className={fullWidth ? 'w-full' : 'w-max'}
                variant={buttonVariant}
                loading={loading || addProps?.isLoading}
                testId={href}
                target={data?.new_tab ? '_blank' : undefined}
                href={localizeUrl(href, locale)}
              >
                {data.text || addProps?.ctaText}
              </Button>
            </NextLinkSingleApp>
          ) : (
            <Button
              className="w-max"
              loading={loading || addProps?.isLoading}
              variant={buttonVariant}
              size="lg"
            >
              {data.text || addProps?.ctaText}
            </Button>
          )}
        </div>
      ) : (
        <CTALink
          app={targetApp || App.UA}
          text={data.text}
          href={data.href}
          onClick={() => {
            handleCTAClick(trackingDataNewFormat);
            onCTAClick();
          }}
          color={textColor}
        />
      )}
    </>
  );
};
