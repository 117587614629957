import { Skeleton } from '@finn/design-system/atoms/skeleton';
import { useFormattedNumber, useGetPreviousCart } from '@finn/ua-vehicle';
import { getTermLabel, useIntl } from '@finn/ui-utils';
import React, { ReactNode } from 'react';

import { useGetPreviousDeal } from '~/modules/checkout/hooks/useGetPreviousDeal';

const InfoRow: React.FC<{ label: string; value: string | ReactNode }> = ({
  label,
  value,
}) => {
  return (
    <>
      <span className="body-14-light">{label}</span>
      <span className="body-14-semibold">{value}</span>
    </>
  );
};

export const AttributesBlock: React.FC = () => {
  const { data: dealData, isLoading } = useGetPreviousDeal();
  const previousCart = useGetPreviousCart();
  const data = dealData || previousCart;
  const i18n = useIntl();

  const monthlyTotalLabel = i18n.formatMessage('checkout.monthlyTotalLabel');
  const contractDurationLabel = i18n.formatMessage('checkout.contractDuration');
  const flexTermLabel = i18n.formatMessage('checkout.flexTermLabel');
  const kmIncludedLabel = i18n.formatMessage('checkout.kmIncluded');
  const kmPackage = useFormattedNumber(data?.kmPackage || 1000);
  const termInfo = getTermLabel(Number(data?.term || 6), flexTermLabel, i18n);
  const kmPackageValue = i18n.formatMessage(
    'checkout.kmPackageForMonth',

    {
      kmPackage,
    }
  );

  const attributes = [
    {
      label: monthlyTotalLabel,
      value: isLoading ? (
        <Skeleton className="h-full w-full" />
      ) : (
        i18n.formatNumber(Math.ceil(data?.amount || 0), {
          style: 'currency',
          currency: 'EUR',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
      ),
    },
  ];

  if (termInfo && kmPackage) {
    attributes.splice(
      0,
      0,
      ...[
        { label: contractDurationLabel, value: termInfo },
        { label: kmIncludedLabel, value: kmPackageValue },
      ]
    );
  }

  return (
    <div className="grid grid-cols-2 gap-2">
      {attributes.map(({ label, value }) => (
        <InfoRow key={label} label={label} value={value} />
      ))}
    </div>
  );
};
