import { getFormattedDate } from '@finn/platform-modules/ssr-dates';
import { IntlShape } from '@finn/ui-utils';
import { toUniversalTime } from '@finn/ui-utils/ssr';
import dayjs from 'dayjs';

import { FUEL, GenericVehicleDetails } from '../types';

export const SOURCES = {
  NOCODB: 'nocodb',
  AIRTABLE: 'airtable',
  BLUE_DRAGON: 'blue_dragon',
};

export const isElectricVehicle = (fuel: string) =>
  fuel === FUEL.ElectricDE || fuel === FUEL.ElectricEN;

export const isVehicleComingSoon = (
  vehicle: GenericVehicleDetails
): boolean => {
  // availability overrides coming soon status
  return !vehicle.availability && !!vehicle?.coming_soon;
};

type GetEarliestDeliveryParams = {
  availableFrom: string;
  deviationInWeeks: number | undefined;
  intl: IntlShape;
};

/**
 * Gets the earliest formatted delivery date.
 *
 * Possible states examples:
 * 17.02.2025 (exact date, no deviation)
 * February 2025 (deviation, same year & month)
 * February/March 2025 (deviation, same year, different month)
 * February 2024/March 2025 (deviation, different year & month)
 */
export const getEarliestDelivery = ({
  availableFrom,
  deviationInWeeks,
  intl,
}: GetEarliestDeliveryParams): string => {
  const earliestDate = toUniversalTime(new Date(availableFrom));

  if (!deviationInWeeks) {
    return getFormattedDate(earliestDate, 'DD.MM.YYYY');
  }
  const availableFromDeviationDate = dayjs(earliestDate).add(
    deviationInWeeks,
    'week'
  );

  const earliestMonth = earliestDate.getMonth();
  const deviatedMonth = availableFromDeviationDate.month();
  const earliestYear = earliestDate.getFullYear();
  const deviatedYear = availableFromDeviationDate.year();

  const sameMonth = earliestMonth === deviatedMonth;
  const sameYear = earliestYear === deviatedYear;

  const earliestMonthName = intl.formatMessage(
    `general.months.${earliestMonth}`
  );

  // Display max. 1 month deviation to avoid unnecessary uncertainty
  const monthNextToEarliest = (earliestMonth + 1) % 12;
  const deviatedMonthName = intl.formatMessage(
    `general.months.${monthNextToEarliest}`
  );

  if (!sameYear) {
    return `${earliestMonthName} ${earliestYear}/${deviatedMonthName} ${deviatedYear}`;
  }

  if (!sameMonth) {
    return `${earliestMonthName}/${deviatedMonthName} ${deviatedYear}`;
  }

  return `${earliestMonthName} ${earliestYear}`;
};

export const getEquipmentLine = (vehicle: GenericVehicleDetails) => {
  const equipmentLine =
    vehicle.source === SOURCES.BLUE_DRAGON
      ? vehicle.trim_name
      : vehicle.equipment_line;

  return equipmentLine ?? '';
};

export const getConfigLineLabel = (vehicle: GenericVehicleDetails): string => {
  return vehicle.source === SOURCES.BLUE_DRAGON
    ? (vehicle.equipment_line ?? '')
    : `${vehicle.engine ?? ''} ${vehicle.equipment_line ?? ''}`;
};
