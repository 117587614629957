import { TIRES_TYPES } from '@finn/ua-constants';
import {
  constructFormattedNumberResponse,
  IntlShape,
  useCurrentLocale,
  useIntl,
} from '@finn/ui-utils';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';

import { getPowerLabel, isElectricVehicle } from '../../product-cards';
import { ListItemProps } from '../components/KeyDataListItem';

export const useFormattedNumber = (
  value: number,
  maximumFractionDigits: number = 2,
  forceDigits?: boolean
) => {
  const intl = useIntl();

  return constructFormattedNumberResponse(
    intl,
    value,
    maximumFractionDigits,
    forceDigits
  );
};

const getTireLabelAndTooltip = (tire: string, intl: IntlShape) => {
  const tireCamelCase = camelCase(tire).trim();

  if (TIRES_TYPES.includes(tireCamelCase)) {
    return {
      label: intl.formatMessage(`pdp.keyData.${tireCamelCase}Label`),
      tooltip: intl.formatMessage(`pdp.keyData.${tireCamelCase}Tooltip`),
    };
  }

  return { label: null, tooltip: null };
};

export const useKeyDataItems = (vehicle): ListItemProps[] => {
  const i18n = useIntl();
  const { region } = useCurrentLocale();
  const consumption = useFormattedNumber(get(vehicle, 'consumption', 0));
  const power = Number(vehicle.power ?? 0);
  const fuel = get(vehicle, 'fuel', '');
  const isElectric = isElectricVehicle(fuel);
  const powerLabel = getPowerLabel(i18n, power, region, isElectric);
  const shouldShowEVRange = get(vehicle, 'ev_range') && isElectric;

  // TODO app-level way to control units
  const consumptionUnit = `${isElectric ? 'kWh' : 'l'} / 100km`;
  const distanceUnit = 'km';

  return [
    {
      id: 1,
      label: get(vehicle, 'model_year'),
      title: i18n.formatMessage('pdp.keyData.modelYear'),
    },
    {
      id: 2,
      label: get(vehicle, 'cartype'),
      title: i18n.formatMessage('pdp.keyData.cartype'),
    },
    {
      id: 3,
      label: `${get(vehicle, 'seats') ?? '-'}/${get(vehicle, 'doors') ?? '-'}`,
      title: i18n.formatMessage('pdp.keyData.seats-doors'),
    },
    {
      id: 4,
      label: get(vehicle, 'engine'),
      title: i18n.formatMessage('pdp.keyData.engine'),
    },
    {
      id: 5,
      label: get(vehicle, 'fuel'),
      title: i18n.formatMessage('pdp.keyData.fuel'),
    },
    {
      id: 6,
      label: get(vehicle, 'config_drive'),
      title: i18n.formatMessage('pdp.keyData.drive'),
    },
    {
      id: 7,
      label: get(vehicle, 'gearshift'),
      title: i18n.formatMessage('pdp.keyData.gearshift'),
    },
    {
      id: 8,
      label: powerLabel,
      title: i18n.formatMessage('pdp.keyData.power'),
    },
    {
      id: 9,
      label: `${consumption} ${consumptionUnit}`,
      title: i18n.formatMessage('pdp.keyData.consumption'),
    },
    {
      id: 11,
      label: shouldShowEVRange
        ? `${get(vehicle, 'ev_range')} ${distanceUnit}`
        : '',
      title: i18n.formatMessage('pdp.keyData.range'),
    },
    {
      id: 12,
      label: getTireLabelAndTooltip(get(vehicle, 'tires'), i18n).label,
      title: i18n.formatMessage('pdp.keyData.tires'),
      additionalInfo: getTireLabelAndTooltip(get(vehicle, 'tires'), i18n)
        .tooltip,
    },
  ];
};
