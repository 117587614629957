import { checkAccountSwitch, register } from '@finn/ua-auth';
import { usePCPLoginExp } from '@finn/ua-featureflags';
import { ModalKey, useCloseModal } from '@finn/ua-modals';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { recaptchaCall } from '@finn/ui-components';
import {
  config,
  CookieKeys,
  getClientCookie,
  getSession,
  Locale,
  removeLocalTimezoneOffset,
  trackEvent,
  useCurrentLocale,
} from '@finn/ui-utils';
import dayjs from 'dayjs';
import { useRouter } from 'next/compat/router';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLoginStore } from '../store/useLoginStore';

export const useRegistrationHandler = ({
  createLead,
  setExtendedAccountInProgress,
}: {
  createLead?: (data) => Promise<void>;
  setExtendedAccountInProgress?: (value: boolean) => void;
}) => {
  const { locale } = useCurrentLocale();
  const form = useFormContext();
  const setServerErrorCode = useLoginStore((state) => state.setServerErrorCode);
  const setFormValues = useLoginStore((state) => state.setFormValues);
  const trackSubmitClick = useCallback((data) => {
    interactionTrackingEvent(TrackingEventName.REGISTER_CTA_CLICKED, {
      authenticationMethod: 'email',
      ...(data || {}),
    });
  }, []);
  const trackFailure = useCallback((error = '', data) => {
    interactionTrackingEvent(TrackingEventName.ACCOUNT_CREATION_FAILED, {
      authenticationMethod: 'email',
      error,
      ...(data || {}),
    });
  }, []);
  const router = useRouter();
  const { isEnabled: isPCPLoginExp } = usePCPLoginExp();
  const isCart = router?.asPath.includes('cart') || isPCPLoginExp;
  const setSubmittedLogin = useLoginStore((state) => state.setSubmittedLogin);
  const closeModal = useCloseModal();

  return useCallback(async () => {
    setFormValues(form?.getValues() || {});
    const email = form?.getValues('email') || '';
    const password = form?.getValues('password') || '';
    const firstname = form?.getValues('firstname') || '';
    const lastname = form?.getValues('lastname') || '';
    const phone = form?.getValues('phone') || '';
    const birthday = form?.getValues('birthday') || '';

    const shouldCreateLead = !!(
      firstname &&
      lastname &&
      phone &&
      birthday &&
      isCart
    );

    setServerErrorCode(null);

    trackSubmitClick({ source: 'embedded-login' });
    if (shouldCreateLead) {
      setExtendedAccountInProgress?.(true);
    }
    setSubmittedLogin(true);

    const token = await recaptchaCall();
    const result = await register(
      email,
      password,

      locale as Locale,
      token,
      getClientCookie(CookieKeys.E2E_SECRET) ||
        config.RECAPTCHA_E2E_SECRET ||
        '',
      {
        firstname,
        lastname,
        phone,
        birthday: removeLocalTimezoneOffset(dayjs(birthday)?.toDate())
          ?.getTime()
          ?.toString(),
      }
    );
    if (result.ok) {
      const session = await getSession();
      if (shouldCreateLead) {
        try {
          await createLead?.(form?.getValues());
        } catch {
          setSubmittedLogin(false);
          setExtendedAccountInProgress?.(false);
        }
      }
      closeModal(ModalKey.LOGIN);
      trackEvent('Account Created', {
        contact_id: '',
        origin: 'checkout',
      });
      await checkAccountSwitch({ session });
    } else {
      trackFailure(result.error, { source: 'embedded-login' });
      setServerErrorCode(result.error);
    }
    if (!shouldCreateLead) {
      setSubmittedLogin(false);
    }
  }, [
    setFormValues,
    form,
    isCart,
    setServerErrorCode,
    trackSubmitClick,
    setSubmittedLogin,
    locale,
    setExtendedAccountInProgress,
    createLead,
    trackFailure,
    closeModal,
  ]);
};
