import {
  App,
  config,
  Locale,
  localizeUrl,
  useCurrentApp,
  useCurrentLocale,
} from '@finn/ui-utils';
import { useRouter } from 'next/compat/router';
import Link from 'next/link';
import { MouseEvent, ReactNode } from 'react';

type Props = {
  app: App;
  href: string;
  as?: string;
  target?: string;
  title?: string;
  passHref?: boolean;
  className?: string;
  withoutAnchorTag?: boolean;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  children?: ReactNode;
};

// use <Link> from next/link only if the current app is the same as the target app
// other apps will fall back to <a> tag
// for sites served over url rewrite, NextJS can't fetch the .json version
// of the page due to site id mismatch so, it needs direct linking
export const NextLinkSingleApp = ({
  children,
  app,
  href,
  target,
  onClick,
  className,
  withoutAnchorTag,
  ...otherProps
}: Props) => {
  const currentApp = useCurrentApp();
  const { locale } = useCurrentLocale();
  const router = useRouter();
  const isPageRouter = Boolean(router);

  let fixedLocale = undefined;
  let fixedHref = href;
  if (
    (href.startsWith(`/${Locale.GERMAN_GERMANY}`) ||
      href.startsWith(`${config.FINN_WEB_URL}/${Locale.GERMAN_GERMANY}`)) &&
    locale !== Locale.GERMAN_GERMANY
  ) {
    fixedLocale = Locale.GERMAN_GERMANY;
    fixedHref = href.substring(href.indexOf(`/${Locale.GERMAN_GERMANY}`) + 6);
  } else if (!isPageRouter && !href?.includes('http')) {
    // for app router we need manually add locale to links
    fixedHref = `/${Locale.GERMAN_GERMANY}${href}`;
  }

  const shouldRenderLink = currentApp === app;
  if (withoutAnchorTag) {
    return shouldRenderLink ? (
      <Link href={href} legacyBehavior {...otherProps}>
        {children}
      </Link>
    ) : (
      <>{children}</>
    );
  }

  return shouldRenderLink ? (
    <Link
      href={fixedHref}
      locale={fixedLocale}
      legacyBehavior
      {...otherProps}
      target={target}
      title={otherProps.title}
    >
      <a onClick={onClick} className={className}>
        {children}
      </a>
    </Link>
  ) : (
    <a
      href={localizeUrl(href, locale)}
      onClick={onClick}
      target={target}
      className={className}
      title={otherProps.title}
    >
      {children}
    </a>
  );
};
