'use client';
import { useEffect, useState } from 'react';

// to conditionally render react component we can't use isServer() directly. instead we should use this hook
export const useIsomorphicIsServer = () => {
  const [isServer, setIsServer] = useState(true);
  useEffect(() => {
    setIsServer(false);
  }, []);

  return isServer;
};
