import { type GenericVehicleDetails } from '@finn/ua-vehicle';
import { type BasePageData, type SeoHeaderData } from '@finn/ui-cosmic';
import { config, replaceStrKeys } from '@finn/ui-utils/ssr';
import { Metadata } from 'next';

export const getCurrentUrl = (locale: string, asPath: string) => {
  const nonDefaultLocale = locale === 'default' ? 'de-DE' : locale;
  const pathWithoutQuery = asPath.split(/[?#]/)[0];
  const fixedRootPath = pathWithoutQuery === '/' ? '' : pathWithoutQuery;

  return `${config.FINN_WEB_URL}/${nonDefaultLocale}${fixedRootPath}`;
};

export const getStrippedPath = ({
  isAbTestPage,
  pathname,
  asPath,
}: {
  asPath?: string;
  pathname?: string;
  isAbTestPage?: boolean;
}) => {
  // Remove trailing slash
  const length = asPath?.length;

  // AB test pages should not return a path than the original one
  if (isAbTestPage) {
    return '';
  }

  if (pathname) {
    return pathname;
  }

  if (length > 0 && asPath.charAt(length - 1) === '/') {
    return asPath.substring(0, length - 1);
  }

  return asPath;
};

export const getDescription = (
  seoHeader: SeoHeaderData,
  vehicle?: GenericVehicleDetails
) => {
  let description = seoHeader?.metadata?.og_description || '';
  description = replaceStrKeys(description, {
    brand: vehicle?.brand?.id || '',
    model: vehicle?.model || '',
  });

  return description;
};

type Params = {
  pageData?: BasePageData;
  tabTitle?: string;
  canonicalUrl?: string;
  asPath: string;
  lang?: string;
  isNoIndexNoFollow?: boolean;
};

export const generatePageMetadata = async ({
  pageData,
  isNoIndexNoFollow,
  lang = 'de-DE',
  asPath = '/',
  canonicalUrl,
  tabTitle,
}: Params): Promise<Metadata> => {
  const seoHeader = pageData?.metadata?.seo_header;
  const pageTitle = tabTitle || seoHeader?.metadata?.title || '';
  const noIndexProperty = seoHeader?.metadata?.no_index;
  const description = getDescription(seoHeader);

  const isAbTestPage = asPath.startsWith('/ab');
  const isHomePageAB = asPath.startsWith('/ab/home-new');

  const isMultiLocale =
    ((!isAbTestPage || isHomePageAB) && seoHeader?.metadata?.multi_locale) ||
    false;

  const currentUrl = getCurrentUrl(lang, asPath);
  const canonical = canonicalUrl || currentUrl;

  let isNoIndex = false;
  if (isNoIndexNoFollow === null || isNoIndexNoFollow === undefined) {
    if (typeof noIndexProperty === 'string') {
      isNoIndex = noIndexProperty === 'true';
    } else if (typeof noIndexProperty === 'boolean') {
      isNoIndex = noIndexProperty;
    }
  } else {
    isNoIndex = isNoIndexNoFollow;
  }

  return {
    title: pageTitle,
    description,
    keywords: seoHeader?.metadata?.keywords || '',
    verification: {
      google: config.GMC_TAG,
    },
    robots: isNoIndex
      ? {
          index: false,
          follow: true,
        }
      : undefined,
    openGraph: {
      title: seoHeader?.metadata?.og_title || pageTitle,
      type: ['website', 'article', 'profile'].includes(
        seoHeader?.metadata?.og_type
      )
        ? (seoHeader?.metadata?.og_type as any)
        : 'website',
      url: seoHeader?.metadata?.og_url || currentUrl,
      description: seoHeader?.metadata?.og_description,
      images: [
        {
          url: seoHeader?.metadata?.og_image.url,
          width: seoHeader?.metadata?.og_image_width,
          height: seoHeader?.metadata?.og_image_height,
          alt: seoHeader?.metadata?.og_title || pageTitle,
        },
      ],
      siteName: seoHeader?.metadata?.og_site_name,
    },
    alternates:
      canonical || isMultiLocale
        ? {
            canonical,
            ...(isMultiLocale && {
              languages: {
                'de-DE': `${config.FINN_WEB_URL}/de-DE${getStrippedPath({ isAbTestPage, asPath })}`,
              },
            }),
          }
        : undefined,
  };
};
