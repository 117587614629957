import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type RibbonState = {
  isRibbonDismissed?: boolean;
  setIsRibbonDismissed: (value: boolean) => void;
};

export const useRibbonStore = create<RibbonState>()(
  devtools(
    immer((set) => ({
      setIsRibbonDismissed: (value) => {
        set((state) => {
          state.isRibbonDismissed = value;
        });
      },
    })),
    { name: 'RibbonStore' }
  )
);
