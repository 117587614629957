import { CarFilled } from '@finn/design-system/icons/car-filled';
import { Close } from '@finn/design-system/icons/close';
import { FilledGift } from '@finn/design-system/icons/filled-gift';
import { HeartFilled } from '@finn/design-system/icons/heart-filled';
import { Public } from '@finn/design-system/icons/public';
import { RocketLaunchFilled } from '@finn/design-system/icons/rocket-launch-filled';
import { SaleBadgeFilled } from '@finn/design-system/icons/sale-badge-filled';
import { StarFilled } from '@finn/design-system/icons/star-filled';
import { WarningFilled } from '@finn/design-system/icons/warning-filled';
import { NextLinkSingleApp } from '@finn/ui-components';
import { HeaderData } from '@finn/ui-cosmic';
import { App, cn, CookieKeys, trackEvent } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useRef } from 'react';

import { useRibbonStore } from './useRibbonStore';

const ICONS = new Map<
  String,
  React.ComponentType<React.SVGProps<SVGSVGElement>>
>([
  ['Star', StarFilled],
  ['Earth', Public],
  ['Heart', HeartFilled],
  ['Warning', WarningFilled],
  ['Gift', FilledGift],
  ['RocketLaunch', RocketLaunchFilled],
  ['PriceTag', SaleBadgeFilled],
  ['Car', CarFilled],
]);

export const Ribbon: React.FC<{ data: HeaderData; onDismiss?: () => void }> = ({
  data,
  onDismiss,
}) => {
  const { isRibbonDismissed, setIsRibbonDismissed } = useRibbonStore();
  const ribbonRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setIsRibbonDismissed(!!Cookies.get(CookieKeys.RIBBON_DISMISSED));
  }, []);

  useEffect(() => {
    if (isRibbonDismissed) {
      if (ribbonRef.current) {
        ribbonRef.current.classList.add('hidden');
      }
    }
  }, [isRibbonDismissed]);

  const dismissRibbon = useCallback((e: React.MouseEvent) => {
    trackEvent('Header Ribbon Dismissed', {});
    e.stopPropagation();
    setIsRibbonDismissed(true);
    Cookies.set(CookieKeys.RIBBON_DISMISSED, 'true', { expires: 1 });
    onDismiss?.();
  }, []);

  const clickRibbon = useCallback(() => {
    trackEvent('Header Ribbon Clicked', {});
  }, []);

  const IconComponent =
    data.metadata.ribbon && ICONS.get(data.metadata.ribbon?.icon.key);

  return (
    data.metadata.ribbon?.enabled && (
      <>
        <NextLinkSingleApp
          // the z index ensures ribbon remains visible when our image gallery is open on mobile.
          className="relative z-10 md:z-0"
          app={App.UA}
          href={data.metadata.ribbon.link_target}
        >
          <span
            ref={ribbonRef}
            style={{
              color: data.metadata.ribbon?.text_color,
              backgroundColor: data.metadata.ribbon?.background_color,
            }}
            onClick={clickRibbon}
            className={cn(
              'flex w-full cursor-pointer justify-center p-3 pr-8 text-center text-sm font-medium transition-opacity duration-500 ease-in-out',
              {
                'opacity-100': isRibbonDismissed === false,
                'opacity-0':
                  isRibbonDismissed === undefined || isRibbonDismissed,
                'hover:opacity-90': isRibbonDismissed === false,
              }
            )}
          >
            {IconComponent && (
              <IconComponent
                style={{
                  fill: data.metadata.ribbon?.text_color || 'white',
                }}
                className="mr-2 h-4"
              />
            )}
            {data.metadata.ribbon.text}
          </span>
        </NextLinkSingleApp>
        <Close
          onClick={dismissRibbon}
          style={{
            fill: data.metadata.ribbon?.text_color || 'white',
          }}
          className="absolute right-0 top-0 z-10 m-2 cursor-pointer rounded p-0.5 transition-transform hover:scale-110"
        />
      </>
    )
  );
};
