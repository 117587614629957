import { LoginModal, SpacingWrapper } from '@finn/platform-modules';
import { Header } from '@finn/ua-header';
import { ModalContainer, ModalKey } from '@finn/ua-modals';
import { getCheckoutCartUrl, useConfigureStore } from '@finn/ua-vehicle';
import { Breadcrumb, ErrorBoundary } from '@finn/ui-components';
import { BasePageData, MinimisedFooterData } from '@finn/ui-cosmic';
import { isMobileApp } from '@finn/ui-utils';
import React, { ReactNode } from 'react';

import Footer from '~/components/Footer';
import CheckoutFooter from '~/components/Footer/CheckoutFooter';
import { CartButton } from '~/modules/checkout/components/CartButton/CartButton';

import MinimisedFooter from '../components/Footer/MinimisedFooter';
import { EmptyCartModal } from '../modules/checkout/components/CartModals/EmptyCartModal';
import { ReturnToCheckoutModal } from '../modules/checkout/components/CartModals/ReturnToCheckoutModal';

type Props = {
  data: BasePageData;
  checkoutFooter?: boolean;
  hideHeaderFooter?: boolean;
  isMobile?: boolean;
  children?: ReactNode;
  stickyHeader?: boolean;
  footerBreadcrumbs?: Breadcrumb[];
  isThankYouPage?: boolean;
  onBreadcrumbClick?: (breadcrumb: Breadcrumb, index: number) => void;
};

const defaultStickyCTA: any = {
  text: 'Auto finden',
  href: '/subscribe/',
  cta_type: {
    key: 'button',
    value: 'Button',
  },
  tracking_location: 'Header',
};

const DefaultLayout = ({
  children,
  data,
  checkoutFooter,
  hideHeaderFooter,
  isMobile,
  footerBreadcrumbs,
  onBreadcrumbClick,
  stickyHeader = false,
  isThankYouPage = false,
}: Props) => {
  const headerData = data.metadata.header;
  const footerData = data.metadata.footer;

  const {
    downPayment,
    kilometerPackage,
    term,
    vehicle: configureVehicle,
  } = useConfigureStore();

  const downPaymentAmountInVehicle =
    configureVehicle?.downpayment_prices?.downpayment_fixed_amount;

  const downPaymentAmount = downPayment
    ? downPaymentAmountInVehicle
    : undefined;

  const headerProps = {
    sticky: stickyHeader,
    stickyCTAData: stickyHeader && defaultStickyCTA,
    data: headerData,
    CartButton,
  };

  if (isMobile || isMobileApp() || hideHeaderFooter) {
    return (
      <div className="relative min-h-screen">
        {/*
          we want to mount invisible header, as we want to have
          some modals available that rendered inside header
        */}
        <div className="hidden">
          <ErrorBoundary>
            <Header {...headerProps} />
          </ErrorBoundary>
        </div>
        <ModalContainer
          modalKey={ModalKey.LOGIN}
          ModalComponent={LoginModal}
          cartURL={getCheckoutCartUrl(
            configureVehicle?.id ?? '',
            term,
            kilometerPackage,
            downPaymentAmount
          )}
        />
        {children}
      </div>
    );
  }

  const isB2BPageFooter = footerData?.slug?.includes?.('b2b');

  return (
    <div className="relative min-h-screen">
      <ErrorBoundary>
        <Header {...headerProps} />
      </ErrorBoundary>
      <ModalContainer
        modalKey={ModalKey.LOGIN}
        ModalComponent={LoginModal}
        cartURL={getCheckoutCartUrl(
          configureVehicle?.id ?? '',
          term,
          kilometerPackage,
          downPaymentAmount
        )}
      />
      {children}
      {checkoutFooter && (
        <ErrorBoundary>
          <div className="pb-24 sm:pb-16" />
          <CheckoutFooter />
        </ErrorBoundary>
      )}
      {isB2BPageFooter && (
        <ErrorBoundary>
          <SpacingWrapper>
            <MinimisedFooter
              data={footerData as unknown as MinimisedFooterData}
            />
          </SpacingWrapper>
        </ErrorBoundary>
      )}
      {!checkoutFooter && !isB2BPageFooter && (
        <ErrorBoundary>
          <Footer
            data={footerData}
            breadcrumbs={footerBreadcrumbs}
            onBreadcrumbClick={onBreadcrumbClick}
            variant={isThankYouPage ? 'minimal' : 'default'}
          />
        </ErrorBoundary>
      )}

      <ModalContainer
        modalKey={ModalKey.EMPTY_CART}
        ModalComponent={EmptyCartModal}
      />
      <ModalContainer
        modalKey={ModalKey.RETURN_TO_CHECKOUT}
        ModalComponent={ReturnToCheckoutModal}
      />
    </div>
  );
};

export default DefaultLayout;
