import { Button } from '@finn/design-system/atoms/button';
import { useIntl } from '@finn/ui-utils';
import React from 'react';

import { LoginScreen, useLoginStore } from './store/useLoginStore';

export const SwitchToLogin: React.FC = () => {
  const i18n = useIntl();
  const alreadyHaveAccount = i18n.formatMessage(
    'userAccount.registration.alreadyHaveAccount'
  );
  const logIn = i18n.formatMessage('userAccount.login.subtitle');
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const shouldShowRegistration = loginScreen === LoginScreen.registrationForm;

  const onSwitch = () => {
    setLoginScreen(LoginScreen.emailCheckForm);
  };

  if (!shouldShowRegistration) {
    return null;
  }

  return (
    <div className="mt-1 inline-flex items-center gap-2">
      <div className="body-16-light">{alreadyHaveAccount}</div>
      <Button
        size="lg"
        variant="action"
        data-testid="switch-to-login"
        onClick={onSwitch}
      >
        {logIn}
      </Button>
    </div>
  );
};
