import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isBetween);
dayjs.extend(CustomParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

require('dayjs/locale/de');

dayjs.locale('de');

type dayjsDate = string | dayjs.Dayjs | Date;

export const isValidDate = (
  date: dayjsDate,
  format?: string,
  strict?: boolean
): boolean => {
  return dayjs(date, format, strict).isValid();
};

export const toDateInstance = (
  date: string | Date,
  format?: string,
  strict?: boolean
) => {
  if (!isValidDate(date, format, strict)) {
    return '';
  }

  return format ? dayjs(date, format, strict) : dayjs(date);
};

export const toISODate = (dateValue?: dayjsDate) => {
  if (dayjs.isDayjs(dateValue)) {
    return dateValue.toISOString();
  }

  const dateInstance = toDateInstance(dateValue);

  if (!dateInstance) {
    return '';
  }

  return dateInstance.toISOString();
};

export const isAfter = (
  date: dayjsDate,
  dateToCompare: dayjsDate,
  unit?: 'millisecond' | 'day' | 'month' | 'year'
) => {
  return dayjs(date).isAfter(dateToCompare, unit);
};

export const isInThePast = (date: dayjsDate) => {
  if (!isValidDate(date)) {
    throw new Error('Invalid date');
  }

  const today = dayjs();

  return isAfter(today, date, 'day');
};

export const daysFromNowToDate = (date: string) => {
  if (!date) {
    // achieves backward compatible with the previous implementation
    // some usages are changing for the worse if "days between today and today" simply returns zero
    return NaN;
  }

  let today = dayjs();
  let futureDate = dayjs(date);

  // Set the time for both dates to midnight
  futureDate = futureDate.startOf('day');
  today = today.startOf('day');

  const difference = futureDate.diff(today, 'days');

  const daysLeft = Math.ceil(difference);

  return daysLeft;
};

export const getAgeInYears = (birthday: dayjsDate) =>
  dayjs().diff(birthday, 'year');

export const isSameDate = (
  date1: dayjsDate,
  date2: dayjsDate,
  unit: dayjs.OpUnitType = 'minute'
) =>
  !dayjs.isDayjs(date1) || !dayjs.isDayjs(date2)
    ? false
    : date1.isSame(date2, unit);

export const getFormattedDate = (date: dayjsDate, format = 'DD.MM.YYYY') => {
  const dayjsDate = dayjs.isDayjs(date) ? date : toDateInstance(date);

  if (!date || !dayjsDate) {
    return '';
  }

  return dayjsDate.format(format);
};

export const dateDiff = (firstDate: string, secondDate: string) => {
  // Day in ms
  const day = 1000 * 60 * 60 * 24;

  const firstDateMs = Number(toDateInstance(firstDate).valueOf());
  const secondDateMs = Number(toDateInstance(secondDate).valueOf());

  const diff = secondDateMs - firstDateMs;

  return Math.round(diff / day);
};

export const getFinnRequestId = (): string => {
  const requestId =
    new Date().getTime().toString(36) + Math.random().toString(36).substr(2, 9);

  return requestId;
};
