import { useRouter } from 'next/compat/router';
import { usePathname } from 'next/navigation';

export const useIsPlpPage = () => {
  const router = useRouter();
  const pathname = usePathname();

  // back compatability between next/router and next/navigation
  const finalPathname = router?.asPath || pathname;

  return (
    router?.route === '/[...slug]' && finalPathname?.startsWith('/subscribe')
  );
};
